/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, {useEffect, useState} from "react";
import "../../custom.css";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {Spinner} from "react-bootstrap";
import {makeStyles} from "@material-ui/core/styles";
import {PageView, Timing} from "../GoogleAnalytics";
import LongArrowDownIcon from "@material-ui/icons/ArrowDownward";
import LongArrowUpIcon from "@material-ui/icons/ArrowUpward";
import FleetService from "../../services/fleet.service";

const MAKE = "MAKE";
const MODEL = "MODEL";
const AGE = "AGE";
const ASC = "asc";
const DESC = "desc";
const SINGLE = "single";
const DUAL = "dual";
const VARIABLE = "variable";
const ECM = "ecm";
const PSC = "psc";
const SIC = "sic";
const SIN = "sin";
const SPARK = "spark";

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
        },
    },
    loader: {
        marginLeft: '225px',
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
            width: `calc(100% + 450px)`,
        },
    }
}));

function Fleet({ isLoading, currentPartnerId, _isMounted }) {
    
    const classes = useStyles();
    
    const [isMounted, setIsMounted] = useState(false);
    
    const [isLoadingFleetData, setIsLoadingFleetData] = useState(false);
    const [partnerFleetDataList, setPartnerFleetDataList] = useState([]);

    const [columnSort, setColumnSort] = useState({ column: "", direction: ASC });
    
    const [upgradeOpportunities, setUpgradeOpportunities] = useState({
        singleECMCount: 0,
        singlePSCCount: 0,
        dualECMCount: 0,
        dualPSCCount: 0,
        variableECMCount: 0,
        variablePSCCount: 0,
        igniterSICCount: 0,
        igniterSINCount: 0,
        igniterSparkCount: 0
    });

    useEffect(() => {
        setIsMounted(true);

        PageView();
        getPartnerFleet().then();

        return () => {
            setIsMounted(false);
        };
    }, [isMounted]);

    const getPartnerFleet = async () => {
        if (!isLoading && _isMounted && isMounted) {
            setIsLoadingFleetData(true);
            
            const startTime = performance.now();
            
            await FleetService.getpartnerfleet(currentPartnerId)
                .then(response => {
                    if (_isMounted && isMounted) {
                        if (response !== undefined && response !== null) {
                            setPartnerFleetDataList(response);
                            calculateUpgradeOpportunities(response);
                        }
                    }

                    const elapsedTime = performance.now() - startTime;
                    Timing("Partner Fleet Loading", "loading", elapsedTime, "Partner Fleet Loading");

                    setIsLoadingFleetData(false);
                })
                .catch(e => {
                    setIsLoadingFleetData(false);
                    console.log(e);
                });
        }
    }
    
    const calculateUpgradeOpportunities = (dataList) => {
        let counts = {
            singleECMCount: 0,
            singlePSCCount: 0,
            dualECMCount: 0,
            dualPSCCount: 0,
            variableECMCount: 0,
            variablePSCCount: 0,
            igniterSICCount: 0,
            igniterSINCount: 0,
            igniterSparkCount: 0
        };

        for (let i = 0; i < dataList.length; i++) {
            let item = dataList[i];

            if (item.device.propertiesJson !== null) {
                let properties = JSON.parse(item.device.propertiesJson);

                if (properties.BlowerStages.toLowerCase() === SINGLE && properties.BlowerType.toLowerCase() === ECM) {
                    counts.singleECMCount++;
                } else if (properties.BlowerStages.toLowerCase() === SINGLE && properties.BlowerType.toLowerCase() === PSC) {
                    counts.singlePSCCount++;
                } else if (properties.BlowerStages.toLowerCase() === DUAL && properties.BlowerType.toLowerCase() === ECM) {
                    counts.dualECMCount++;
                } else if (properties.BlowerStages.toLowerCase() === DUAL && properties.BlowerType.toLowerCase() === PSC) {
                    counts.dualPSCCount++;
                } else if (properties.BlowerStages.toLowerCase() === VARIABLE && properties.BlowerType.toLowerCase() === ECM) {
                    counts.variableECMCount++;
                } else if (properties.BlowerStages.toLowerCase() === VARIABLE && properties.BlowerType.toLowerCase() === PSC) {
                    counts.variablePSCCount++;
                }

                if (properties.IgniterType.toLowerCase() === SIC) {
                    counts.igniterSICCount++;
                } else if (properties.IgniterType.toLowerCase() === SIN) {
                    counts.igniterSINCount++;
                } else if (properties.IgniterType.toLowerCase() === SPARK) {
                    counts.igniterSparkCount++;
                }
            }
        }

        setUpgradeOpportunities(counts);
    }

    const iconFunction = (columnName)  => {
        let arrowIcon = columnSort.direction === ASC ?
                <LongArrowDownIcon style={{ height: '16px', width: '15px', paddingBottom: '2px' }} />
                : <LongArrowUpIcon style={{ height: '16px', width: '15px', paddingBottom: '2px' }} />;

        return columnSort.column === columnName ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : null;
    }

    const onColumnClick = (column) => {
        const newDirection = columnSort.direction === ASC ? DESC : ASC;
        setColumnSort({ column, direction: newDirection });
        sortTable(column);
    };

    const sortTable = (column) => {
        let sortedList = [...partnerFleetDataList];
        if (column === MAKE || column === MODEL) {
            sortedList.sort((a, b) => {
                const aValue = a.device[column.toLowerCase()];
                const bValue = b.device[column.toLowerCase()];

                if (aValue < bValue) {
                    return columnSort.direction === ASC ? -1 : 1;
                }

                if (aValue > bValue) {
                    return columnSort.direction === ASC ? 1 : -1;
                }

                return 0;
            });
        } else if (column === AGE) {
            sortedList.sort((a, b) =>
                columnSort.direction === ASC ? a.device.age - b.device.age : b.device.age - a.device.age
            );
        }

        setPartnerFleetDataList(sortedList);
    };
    
    const renderAgeOfFleetContent = () => {
        return isLoadingFleetData ?
            <div className={["loading", classes.root].join(' ')} style={{ display: "block" }}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>
            : renderAgeOfFleetTable();
    }
    
    const renderAgeOfFleetTable = () => {
        let tableContent = <tbody>
        {partnerFleetDataList.map((item, index) => (
            <tr className={"alerts-table-row"} key={index} style={{textAlign: 'center'}}>
                <td style={{borderLeft: '1px solid #ebeef0'}}>{item.device.make}</td>
                <td>{item.device.model}</td>
                <td style={{borderRight: '1px solid #ebeef0'}}>{item.device.age}</td>
            </tr>
        ))}
        </tbody>;

        return (
            <div style={{margin: '20px 30px'}}>
                <div>
                    <table className="table-hover" style={{width: '100%', tableLayout: 'fixed'}}>
                        <thead>
                            <tr className="alerts-table-row" style={{textAlign: 'center'}}>
                                <th>
                                    <div onClick={() => onColumnClick(MAKE)}>
                                        <span style={{cursor: 'pointer'}}>MAKE</span>
                                        {iconFunction(MAKE)}
                                    </div>
                                </th>
                                <th>
                                    <div onClick={() => onColumnClick(MODEL)}>
                                        <span style={{cursor: 'pointer'}}>MODEL</span>
                                        {iconFunction(MODEL)}
                                    </div>
                                </th>
                                <th>
                                    <div onClick={() => onColumnClick(AGE)}>
                                        <span style={{cursor: 'pointer'}}>AGE</span>
                                        {iconFunction(AGE)}
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        {tableContent}
                    </table>
                </div>
            </div>
        );
    }

    const renderUpgradeOpportunitiesContent = () => {
        return isLoadingFleetData ?
            <div className={["loading", classes.root].join(' ')} style={{ display: "block" }}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>
            : renderUpgradeOpportunitiesTables();
    }

    const renderUpgradeOpportunitiesTables = () => {
        let blowerTypeTableContent = <tbody>
            <tr className={"alerts-table-row"} style={{textAlign: 'center'}}>
                <td style={{borderLeft: '1px solid #ebeef0'}}>{'Single'}</td>
                <td>{upgradeOpportunities.singleECMCount}</td>
                <td style={{borderRight: '1px solid #ebeef0'}}>{upgradeOpportunities.singlePSCCount}</td>
            </tr>
            <tr className={"alerts-table-row"} style={{textAlign: 'center'}}>
                <td style={{borderLeft: '1px solid #ebeef0'}}>{'Dual'}</td>
                <td>{upgradeOpportunities.dualECMCount}</td>
                <td style={{borderRight: '1px solid #ebeef0'}}>{upgradeOpportunities.dualPSCCount}</td>
            </tr>
            <tr className={"alerts-table-row"} style={{textAlign: 'center'}}>
                <td style={{borderLeft: '1px solid #ebeef0'}}>{'Variable'}</td>
                <td>{upgradeOpportunities.variableECMCount}</td>
                <td style={{borderRight: '1px solid #ebeef0'}}>{upgradeOpportunities.variablePSCCount}</td>
            </tr>
        </tbody>;

        let igniterTypeTableContent = <tbody>
            <tr className={"alerts-table-row"} style={{textAlign: 'center'}}>
                <td style={{borderLeft: '1px solid #ebeef0'}}>{'SIC'}</td>
                <td style={{borderRight: '1px solid #ebeef0'}}>{upgradeOpportunities.igniterSICCount}</td>
            </tr>
            <tr className={"alerts-table-row"} style={{textAlign: 'center'}}>
                <td style={{borderLeft: '1px solid #ebeef0'}}>{'SIN'}</td>
                <td style={{borderRight: '1px solid #ebeef0'}}>{upgradeOpportunities.igniterSINCount}</td>
            </tr>
            <tr className={"alerts-table-row"} style={{textAlign: 'center'}}>
                <td style={{borderLeft: '1px solid #ebeef0'}}>{'Spark'}</td>
                <td style={{borderRight: '1px solid #ebeef0'}}>{upgradeOpportunities.igniterSparkCount}</td>
            </tr>
        </tbody>;

        return (
            <div style={{margin: '20px 30px'}}>
                <div>
                    <h6>Blower Types</h6>
                    <table className="table-hover" style={{width: '100%', tableLayout: 'fixed'}}>
                        <thead>
                        <tr className="alerts-table-row" style={{textAlign: 'center'}}>
                            <th>
                                <div>
                                    <span style={{cursor: 'pointer'}}>BLOWER</span>
                                </div>
                            </th>
                            <th>
                                <div>
                                    <span style={{cursor: 'pointer'}}>ECM</span>
                                </div>
                            </th>
                            <th>
                                <div>
                                    <span style={{cursor: 'pointer'}}>PSC</span>
                                </div>
                            </th>
                        </tr>
                        </thead>
                        {blowerTypeTableContent}
                    </table>

                    <h6 style={{marginTop: '50px'}}>Igniter Types</h6>
                    <table className="table-hover" style={{width: '50%', tableLayout: 'fixed'}}>
                        <thead>
                        <tr className="alerts-table-row" style={{textAlign: 'center'}}>
                            <th>
                                <div>
                                    <span style={{cursor: 'pointer'}}>IGNITER</span>
                                </div>
                            </th>
                            <th>
                                <div>
                                    <span style={{cursor: 'pointer'}}>COUNT</span>
                                </div>
                            </th>
                        </tr>
                        </thead>
                        {igniterTypeTableContent}
                    </table>
                </div>
            </div>
        );
    }

    return (
        <div>
            <div>
                <div className='infoPage'>
                    <h2 className="pageTitle" id="tabelLabel"
                        style={{borderBottom: 'none', padding: '20px 0px 0px 30px'}}>
                        Partner Fleet
                    </h2>

                    <Tabs>
                        <TabList style={{marginTop: '20px', paddingLeft: '15px'}}>
                            <Tab>Age of Fleet</Tab>
                            <Tab>Upgrade Opportunities</Tab>
                        </TabList>

                        <TabPanel>
                            {renderAgeOfFleetContent()}
                        </TabPanel>

                        <TabPanel>
                            {renderUpgradeOpportunitiesContent()}
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
        </div>
    );
}

export default Fleet;
